//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getGroupDataplanList } from '@/api/enquiry'
import progressBar from '@/components/ProgressBar.vue'

export default {
  components: {
    progressBar
  },
  data() {
    return {
      value:null,
      planList:[],
      activeKey:[],
      OptionsArray:[
        {id:0,name:'繁體中文',value:'zh'},
        {id:1,name:'English',value:'en'},
        {id:2,name:'日本語',value:'jpn'}
      ],
      groupEnquirylocaleId:localStorage.getItem('groupEnquirylocaleId') || 1,
      isShowlanguage:false,
      groupName:'',
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside);//监听点击事件是否点击到除了选择语言外的盒子
    if(localStorage.getItem('groupEnquirylocale') !== null){
      this.$i18n.locale = localStorage.getItem('groupEnquirylocale')
    }
    this.groupName = this.$route.params.group;
  },
  beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside);
  },
  methods:{
    href_mucun(){
      window.location.href = 'https://esim-globals.com/'
    },
    handleClickOutside(event) {
      const languageBox = document.querySelector('.languageBox');
      if (languageBox && !languageBox.contains(event.target)) {
        this.isShowlanguage = false;
      }
    },
    showLanguage(){
      this.isShowlanguage = !this.isShowlanguage
    },
    selectLanguage(item){//选择语言
        localStorage.setItem('groupEnquirylocale',item.value);
        localStorage.setItem('groupEnquirylocaleId',item.id);
        this.isShowlanguage = false;
        this.groupEnquirylocaleId = item.id;
        console.log(this.groupEnquirylocaleId);
        this.$i18n.locale = item.value;
    },
    onSearch(value){
      if(!value) {
        this.planList = []
        return
      }
      if (value&&value.length < 14) {
        this.$message.warning('The length of the card number is invalid')
        return
      }
      this.activeKey = []
      getGroupDataplanList({group:this.$route.params.group, sim: value }).then(res => {
        this.planList = []
        if (res.code==1) {
          if(res.result.length==1) this.activeKey.push(res.result[0].card_dataplan_id)
          res.result = res.result.map(item => ({
            isShow:Object.values(item.pin_puk).some(value => value && value.trim() !== ''),
            ...item,
            // pin_puk: Object.entries(item.pin_puk).map(([key, value]) => ({ key, value }))
          }));
          this.planList = res.result
        }else{
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
